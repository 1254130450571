.footer {
    padding: {
        top: 27px;
        bottom: 27px;
    }

    picture {
        display: block;
        margin-bottom: 13px;
    }

    ul {
        margin-bottom: 16px;

        li {
            position: relative;

            &:not(:last-child)::after {
                position: relative;
                content: '|';
                display: inline-block;
                margin-left: 10px;
                color: #676767;
            }
        }

        a {
            text-decoration: underline;
            color: #00a0bb;
        }
    }

    p {
        max-width: 535px;
        margin-right: auto;
        margin-left: auto;
        color: #000;
        line-height: 18px;
    }

    p:not(:last-child) {
        margin-bottom: 22px;
    }
}

@media screen and (max-width: $screen-sm-max) {
    .footer {


        picture {
            margin-bottom: 35px;
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .footer {
        margin: {
            top: -3.75vw;
        }

        padding: {
            top: 0;
            bottom: 33vw;
        }

        picture {
            margin-bottom: 5.46vw;
        }

        ul {
            margin-bottom: 6.09vw;

            li {
                a {
                    font: {
                        size: 4.68vw;
                    }

                    line-height: 7.81vw;
                }

                &:not(:last-child) {

                    &::after {
                        margin-left: 1.25vw;

                        font: {
                            size: 4.68vw;
                        }

                        line-height: 7.81vw;
                        color: $gray-base;
                    }

                }
            }
        }

        p {
            max-width: 100%;

            font: {
                size: 3.75vw;
            }

            line-height: 5vw;

            &:not(:last-child) {
                margin-bottom: 5.46vw;
            }
        }
    }

    .mobile-button {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;


        background: {
            image: url('../images/footer-button-bg.jpg');
            size: cover;
        }

        .webp & {
            background-image: url('../images/footer-button-bg.webp');
        }

        z-index: 999;

        .btn-getbox {
            padding: 4.68vw;

            .btn-shadow {
                top: 7.65vw;

                background: {
                    size: 100%;
                }
            }
        }

    }
}