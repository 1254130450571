.answer {
    background: {
        color: #f7f7f7;
        image: url("../images/answer-desktop.jpg");
        position: center bottom;
        repeat: no-repeat;
    };

    .webp & {
        background-image: url('../images/answer-desktop.webp');
    }

    .content {
        padding: {
            right: 0;
            left: 38px;
        };
    }

    h2 {
        color: $gray-base;

        span {
            display: block;
            color: $brand-primary;
        }
    }

    h3 {
        max-width: 525px;
        margin-bottom: 34px;
        font: {
            size: 20px;
            weight: 300;
        };
        letter-spacing: .2px;
    }

    p {
        max-width: 530px;
        margin-bottom: 19px;
        color: $gray-base;

        span {
            font-style: italic;
        }

        strong {
            font-weight: 700;
        }
    }

    .answer-button {
        margin-top: 30px;
    }
}

@media screen and (max-width: $screen-sm-max) {
    .answer {
        padding: {
            top: 44px;
        };
        background: #eff9fa;

        .webp &{
            background: #eff9fa;
        }

        h2,
        h3,
        .answer-button {
            text-align: center;
        }

        h3 {
            max-width: none;
        }

        p {
            max-width: none;
        }

        .answer-image {
            display: block;
            margin-bottom: 25px;
        }

        .content {
            padding: 0 15px;
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .answer {
        padding: {
            top: 6.875vw;
            bottom: 5.78vw;
        };

        h3 {
            margin-bottom: 6.875vw;
            padding: 0 2.5vw;
            font: {
                size: 5.625vw;
            };
            line-height: 6.56vw;
        }

        p {
            margin-bottom: 4.84vw;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .content {
            padding: 0 4.68vw;
        }

        .answer-image {
            margin-bottom: 6.25vw;
        }
    }
}
