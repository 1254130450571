@mixin display($x) {
    display: -webkit-box;
    display: -webkit-$x;
    display: -ms-$x;
    display: $x;
}

@mixin flexbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

%flexbox {
    @include flexbox;
}

@mixin inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

%inline-flex {
    @include inline-flex;
}

@mixin flex-direction($value: row) {
    @if $value==row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    }
    @else if $value==column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    }
    @else if $value==column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    }
    @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-dir($args...) {
    @include flex-direction($args...);
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value==nowrap {
        -ms-flex-wrap: none;
    }
    @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
    // No Webkit Box fallback.
    -webkit-flex-flow: $values;
    -moz-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

// http://w3.org/tr/css3-flexbox/#order-property
@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
    $fg-boxflex: $fg;
    @if type-of($fg)=='list' {
        $fg-boxflex: nth($fg, 1);
    }
    -webkit-box-flex: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg-boxflex;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
    @if $value==flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    }
    @else if $value==flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    }
    @else if $value==space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    }
    @else if $value==space-around {
        -ms-flex-pack: distribute;
    }
    @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin flex-just($args...) {
    @include justify-content($args...);
}

@mixin align-items($value: stretch) {
    @if $value==flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    }
    @else if $value==flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    }
    @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value==flex-start {
        -ms-flex-item-align: start;
    }
    @else if $value==flex-end {
        -ms-flex-item-align: end;
    }
    @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value==flex-start {
        -ms-flex-line-pack: start;
    }
    @else if $value==flex-end {
        -ms-flex-line-pack: end;
    }
    @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

// Calc 
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

// Border radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// Positioning
@mixin center($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}