.money-back {
    padding-top: 57px;

    background: {
        color: #015d8f;
        image: url('../images/gaurantee-desktop.jpg');
        position: center center;
        repeat: no-repeat;
        size: auto;
    }

    .webp & {
        background-image: url('../images/gaurantee-desktop.webp');
    }

    color: $body-bg;

    h2 {
        margin-bottom: 232px;

        font: {
            size: 68px;
            weight: 700;
        }

        span {
            display: block;
            margin-bottom: 23px;

            font: {
                size: 90px;
                weight: 300;
            }

            text-transform: uppercase;
            letter-spacing: -4px;
        }
    }

    h3 {
        text-transform: uppercase;

        font: {
            size: 17px;
            weight: 700;
        }

        line-height: 21px;

        span {
            display: block;
        }
    }

    p {
        max-width: 830px;
        margin: 0 auto 26px;
        line-height: 20px;
        color: #c0f7ff;

        span {
            display: block;
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .money-back {
        background: {
            size: cover;
        }

        h2 {
            margin-bottom: 222px;

            font: {
                size: 50px;
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .money-back {
        padding: {
            top: 8.9vw;
        }

        background: {
            image: url('../images/gaurantee-mobile.jpg');
            position: left top;
            size: cover;
        }

        .webp & {
            background-image: url('../images/gaurantee-mobile.webp');
        }

        h2 {
            margin-bottom: 48.8vw;

            font: {
                size: 7.81vw;
            }

            span {
                margin-bottom: 3.59vw;

                font: {
                    size: 14.06vw;
                }

                letter-spacing: -.625vw;
            }
        }

        h3 {
            margin-bottom: 4.84vw;
            padding: 0 0.25vw;

            font: {
                size: 4.68vw;
            }

            line-height: 6.56vw;
            letter-spacing: .0625vw;

            span {
                display: initial;
            }
        }

        p {
            margin-bottom: 0;
            padding: 0 1.25vw;
            line-height: 6.56vw;
        }
    }
}