.benefits {
    padding-top: 46px;
    color: $body-bg;

    background: {
        color: $brand-primary;
        image: url('../images/benefit-desktop.jpg');
        position: center top;
        size: cover;
    }

    .webp &{
        background-image: url('../images/benefit-desktop.webp');
    }

    .swiper-wrapper {
        @include justify-content(center);
    }

    .swiper-slide {
        width: 190px;

        &:not(:last-child) {
            margin-right: 28px;
        }
    }

    .benefit-slider {
        margin-bottom: 14px;
    }

    .icon-benefites {
        width: 152px;
        height: 152px;
        margin: 0 auto 12px;
        background-blend-mode: multiply;
    }

    h2 {
        margin-bottom: 22px;
    }

    h3 {
        max-width: 125px;
        margin: 0 auto 10px;
        font-size: 17px;
        font-weight: 700;
    }

    .icon-ben-1 {
        background-image: url('../images/icon-benefit-1-desktop.png');

        .webp &{
            background-image: url('../images/icon-benefit-1-desktop.webp');
        }
    }

    .icon-ben-2 {
        background-image: url('../images/icon-benefit-2-desktop.png');

        .webp &{
            background-image: url('../images/icon-benefit-2-desktop.webp');
        }
    }

    .icon-ben-3 {
        background-image: url('../images/icon-benefit-3-desktop.png');

        .webp &{
            background-image: url('../images/icon-benefit-3-desktop.webp');
        }
    }

    .icon-ben-4 {
        background-image: url('../images/icon-benefit-4-desktop.png');

        .webp &{
            background-image: url('../images/icon-benefit-4-desktop.webp');
        }
    }

    .icon-ben-5 {
        background-image: url('../images/icon-benefit-5-desktop.png');

        .webp &{
            background-image: url('../images/icon-benefit-5-desktop.webp');
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    .benefits {
        .swiper-slide {
            width: 180px;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .benefits {
        background: {
            image: url('../images/benefits-tablet.jpg');
            size: cover;
        }

        .webp & {
            background-image: url('../images/benefits-tablet.webp');
        }

        p {
            max-width: 187px;
            margin: 0 auto;
        }

        .benefit-slider {
            padding: 0 15px;
        }

        .swiper-wrapper {
            @include justify-content(inherit);
        }

        .swiper-slide {
            width: 190px;

            &:not(:last-child) {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .benefits {
        padding: {
            top: 7.18vw;
            bottom: 7.03vw;
        }

        h2 {
            margin: {
                bottom: 5.93vw;
            }
        }

        h3 {
            max-width: none;

            margin: {
                bottom: 3.28vw;
            }

            font: {
                size: 5.625vw;
            }
        }

        p {
            max-width: 81.25vw;
        }

        .icon-benefites {
            width: 40.31vw;
            height: 40.31vw;

            margin: {
                bottom: 2.18vw;
            }

            background: {
                size: 100%;
            }
        }

        .benefit-slider {
            margin: 0;
            padding: 0;
        }

        .swiper-pagination {
            margin-top: 6.25vw;

            .swiper-pagination-bullet {
                border: .625vw solid #fff;
                background-color: transparent;
            }

            .swiper-pagination-bullet-active {
                background-color: $body-bg;
            }
        }
    }
}