.patent {
    position: relative;
    padding-bottom: 29px;

    background: {
        color: $body-bg;
        image: url('../images/patent-desktop.jpg');
        position: center -31px;
        repeat: no-repeat;
    }

    .webp & {
        background-image: url('../images/patent-desktop.webp');
    }

    h2 {
        color: $gray-base;

        strong {
            color: $brand-primary;
        }
    }

    h3 {
        position: relative;

        font: {
            size: 17px;
            weight: 700;
        }
    }

    p {
        line-height: 20px;
    }

    .heading {
        margin: {
            top: -6px;
            bottom: 42px;
        }
    }

    .patent-block {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .swiper-slide {
        width: 50%;

        &:first-child {
            padding-right: 236px;
            text-align: right;

            h3 {
                &::after {
                    position: relative;
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    top: -2px;
                    background-color: $brand-primary;
                    margin-left: 6px;
                }
            }

            p {
                padding-right: 17px;
            }
        }

        &:last-child {
            padding-left: 234px;

            h3 {
                &::before {
                    position: relative;
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    top: -2px;
                    background-color: $brand-primary;
                    margin-right: 6px;
                }
            }

            p {
                padding-left: 17px;
            }
        }
    }

    .patent-button {
        position: absolute;
        bottom: 50px;
        left: 50%;
        @include translate(-50%, 0);
        z-index: 2;
    }
}

@media screen and (max-width: $screen-md-max) {
    .patent {
        background: {
            position: center top;
        }

        .swiper-slide {
            &:first-child {
                padding-right: 215px;
            }

            &:last-child {
                padding-left: 215px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .patent {
        background: {
            image: url('../images/patent-tablet.jpg');
            position: left top;
        }

        .webp & {
            background-image: url('../images/patent-tablet.webp');
        }

        .container {
            position: relative;
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        .patent-slider {
            padding: {
                left: 401px;
            }
        }

        .patent-button {
            left: 21px;
            bottom: 23px;
            @include translate(0, 0);
        }

        .heading {
            margin-bottom: 44px;
        }

        .swiper-slide {

            &:first-child,
            &:last-child {
                padding: 0;
            }

            &:first-child {
                text-align: left;

                h3 {
                    &::before {
                        position: relative;
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        top: -2px;
                        background-color: $brand-primary;
                        margin-right: 6px;
                    }

                    &::after {
                        display: none;
                    }
                }

                p {
                    padding-left: 17px;
                    padding-right: 0;
                }
            }
        }

        .swiper-container-horizontal {
            .swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    border: none;
                    background-color: #cccccc;
                }

                .swiper-pagination-bullet-active {
                    background-color: #0f93bf;
                }
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .patent {
        padding: {
            top: 62.5vw;
            bottom: 6.25vw;
        }

        background: {
            image: url('../images/patent-mobile.jpg');
            position: left top;
            size: 100%;
        }

        .webp & {
            background-image: url('../images/patent-mobile.webp');
        }

        h3 {
            margin: {
                bottom: 2.81vw;
            }

            font: {
                size: 5.625vw;
            }

            &::before {
                position: relative;
                display: inline-block;
                content: '';
                width: 2.34vw;
                height: 2.34vw;
                top: -.625vw;
                background-color: $brand-primary;
                margin-right: 1.875vw;
            }
        }

        p {
            line-height: 6.58vw;

            padding: {
                left: 4.21vw;
            }

            letter-spacing: .03125vw;
        }

        .heading {
            margin-bottom: 5.625vw;
        }

        .patent-slider {
            padding: 0 2.18vw;
        }

        .patent-block {
            &:not(:last-child) {
                margin-bottom: 4.84vw;
            }
        }

        .swiper-slide {

            &:first-child,
            &:last-child {
                h3 {
                    &::before {
                        position: relative;
                        display: inline-block;
                        content: '';
                        width: 2.34vw;
                        height: 2.34vw;
                        top: -.625vw;
                        margin-right: 1.875vw;
                    }
                }

                p {

                    padding: {
                        left: 4.21vw;
                    }
                }
            }
        }
    }
}