.better-sleep {
    margin-top: -6px;
    color: $gray-base;

    h2 {
        color: $gray-base;

        span {
            display: block;
            color: $brand-primary;
        }
    }

    p {
        max-width: 540px;
        padding-left: 6px;

        strong {
            font-weight: 800;
            letter-spacing: .15px;
        }

        span {
            font-style: italic;
        }
    }

    .get-button {
        margin-top: 24px;
    }

    .safer-sleep {
        max-height: 584px;

        padding: {
            top: 5px;
            left: 35px;
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .better-sleep {
        padding-bottom: 21px;

        .safer-sleep {
            max-height: none;

            padding: {
                top: 0;
                left: 0;
                right: 29px;
            }
        }



        .sleep-text {
            padding-left: 0;

            >* {
                margin-left: -10px;
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .better-sleep {
        padding: {
            bottom: 3.9vw;
        }

        .safer-sleep {
            margin: {
                top: 5vw;
            }

            padding: 0 4.68vw;
        }

        p {
            max-width: none;

            margin: {
                bottom: 4.9vw;
            }

            padding: {
                left: 0;
            }

            letter-spacing: -.05vw;
        }



        .sleep-text {
            padding: 0 4.68vw;

            >* {
                margin-left: 0;
            }

            picture {
                img {
                    margin: {
                        bottom: 3.9vw;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .better-sleep {
        .sleep-text {
            >picture {
                display: block;

                margin: {
                    top: 29px;
                    left: -33px;
                    bottom: 11px;
                }

                img {
                    max-width: none;
                }
            }
        }
    }
}