.join {
    padding: {
        top: 41px;
    }

    h2 {
        margin-bottom: 22px;

        span {
            display: block;
            color: $brand-primary;
        }
    }

    h3 {
        max-width: 510px;

        font: {
            size: 20px;
            weight: 300;
        }
    }

    h4 {
        padding-right: 65px;

        font: {
            size: 26px;
            weight: 700;
        }

        line-height: 30px;
        text-transform: uppercase;
        color: $gray-base;
    }

    h5 {
        font: {
            size: 17px;
            weight: 700;
        }
    }

    .heading {
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 33px;
        padding-bottom: 18px;
    }

    .join-slider {
        margin-bottom: 23px;
        padding: 0 36px;
    }

    .quote {
        @include flexbox;
        @include flex-flow(wrap);

        .client-image {
            display: block;
            width: 211px;
            @include border-radius(4px);
            overflow: hidden;
        }

        .client-review {
            @include calc(width, '100% - 211px');
            padding-left: 23px;
        }

        .client-name {
            display: block;
            color: #4b4b4b;
        }

        .verified {
            display: block;
            margin-bottom: 14px;
        }

        p {
            padding-right: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .quote-small {

        .client-image {
            width: 134px;
        }

        .client-review {
            @include calc(width, '100% - 134px');
            padding-left: 20px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 26px;
        }

        p {
            padding-right: 0;
        }

        +.quote-small {
            padding-top: 30px;
        }
    }

    .swiper-slide {
        &:first-child {
            width: 610px;
            border-right: 1px solid #eaeaea;
            padding-right: 21px;
        }

        &:last-child {
            @include calc(width, '100% - 610px');

            padding: {
                top: 9px;
                left: 30px;
            }
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    .join {

        h2 {
            font-size: 39px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            padding: 0;
        }

        .join-slider {
            padding: 0;
        }

        .swiper-slide {

            &:first-child,
            &:last-child {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .join {
        padding: {
            top: 46px;
            bottom: 41px;
        }

        h2 {
            font: {
                size: 50px;
            }
        }

        h3 {
            max-width: none;
            letter-spacing: 1px;
        }


        h4 {
            padding-right: 70px;
        }

        .heading {
            border: none;
            margin-bottom: 28px;
            padding-bottom: 0;
        }

        .join-slider {
            margin-bottom: 14px;
            clear: both;
        }

        .quote {
            &:not(.quote-small) {
                width: 585px;
                margin: 0 auto;

                .client-review {
                    padding-left: 16px;
                }
            }
        }

        .quote-small {
            position: relative;
            width: 50%;
            border: none;
            float: left;

            .client-review {
                width: 100%;
            }

            .client-image {
                position: absolute;
                top: 27px;
                left: 13px;
            }

            .client-name {
                position: relative;
                min-width: 72px;
                top: -2px;
            }

            .client-name,
            .verified {
                float: left;
            }

            h5,
            p {
                padding: {
                    left: 151px;
                }

                clear: both;
            }

            p {
                padding-right: 15px;
            }

            &:not(:last-child) {
                border: none;
                padding-bottom: 0;
            }

            +.quote-small {
                padding: {
                    top: 0;
                }
            }
        }

        .swiper-slide {
            &:first-child {
                border: none;
                padding: 0;
            }

            &:last-child {
                padding: 0;
            }
        }

        .swiper-pagination-bullets {
            margin: {
                top: 0;
            }

            .swiper-pagination-bullet {
                border: none;
                background-color: #cccccc;
            }

            .swiper-pagination-bullet-active {
                background-color: $brand-primary;
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .join {
        padding: {
            top: 7.18vw;
            bottom: 7.28vw;
        }

        .heading {
            margin-bottom: 5.625vw;
            padding: 0 4.68vw;

        }

        h2 {
            margin-bottom: 4.375vw;

            font: {
                size: 7.81vw;
            }

            letter-spacing: -.22vw;
        }

        h3 {
            padding-right: 6.25vw;

            font: {
                size: 5.625vw;
            }

            line-height: 6.56vw;
            letter-spacing: 0;
        }

        h4 {
            padding-right: 0;

            font: {
                size: 5.625vw;
            }

            line-height: 6.56vw;
        }

        h5 {
            padding-right: 0;

            font: {
                size: 5.625vw;
            }

            line-height: 6.56vw;

        }

        .quote {

            p {
                padding: 0;
            }

            .client-image {
                width: 32.96vw;
            }

            .client-name {
                @include flexbox;
                @include flex-flow(wrap);

                margin: {
                    bottom: 1.56vw;
                }

                font: {
                    size: 4.06vw;
                }

                picture {
                    width: 24.21vw;
                    margin-left: 3.46vw;
                }
            }

            .verified {
                margin-bottom: 5.31vw;

                img {
                    width: 38.43vw;
                }
            }

            &:not(.quote-small) {
                width: 100%;

                .client-review {
                    display: block;
                    width: 100%;
                    max-width: calc(100% - 32.96vw);

                    padding: {
                        top: 3.59vw;
                        left: 4.84vw;
                    }
                }
            }
        }

        .join-slider {
            padding: 0 4.68vw;
            margin-bottom: 0;
        }

        .client-words {
            width: 100%;

            margin: {
                top: 3.75vw;
            }
        }

        .swiper-pagination-bullets {
            margin: {
                top: 6.25vw;
            }
        }
    }
}