.main-banner {
    padding: {
        top: 35px;
        bottom: 47px;
    }

    color: $body-bg;

    background: {
        color: #0280b2;
        image: url('../images/banner-desktop.jpg');
        position: center top;
        repeat: no-repeat;
    }

    .webp & {
        background-image: url('../images/banner-desktop.webp');
    }

    .tagline {
        display: block;

        padding: {
            left: 14px;
        }

        font: {
            size: 20px;
        }

        text: {
            transform: uppercase;
        }

        letter-spacing: 2.5px;

        strong {
            font-weight: 800;
        }
    }

    .banner-text {
        padding: {
            left: 37px;
        }
    }

    ul {
        margin: {
            top: 147px;
            bottom: 22px;
        }

        padding-left: 17px;


        li {
            padding-left: 30px;

            font: {
                size: 23px;
            }

            line-height: 38px;

            background: {
                image: url('../images/icon-check.svg');
                repeat: no-repeat;
                position: left center;
            }
        }
    }
}

.footer-banner {
    padding: {
        top: 43px;
    }

    background: {
        image: url('../images/footer-banner-desktop.jpg');
    }

    .webp & {
        background-image: url('../images/footer-banner-desktop.webp');
    }

    .tagline {
        margin-bottom: 25px;
    }

    h2 {
        font: {
            size: 68px;
            weight: 700;
        }

        line-height: 66px;

        letter-spacing: -2.75px;

        span {
            display: block;

            margin: {
                bottom: 0;
            }

            font: {
                weight: 300;
            }

            line-height: 66px;

            letter-spacing: -2.75px;
        }
    }

    ul {
        margin-top: 160px;
    }
}

@media screen and (max-width: $screen-md-max) {
    .main-banner {
        background: {
            position: 37% top;
        }

        ul {
            margin: {
                top: 155px;
            }

            padding: {
                left: 4px;
            }
        }
    }

    .footer-banner {
        h2 {
            font-size: 63px;
        }

        .banner-text {
            padding: {
                right: 0;
                left: 52px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .main-banner {
        padding: {
            top: 347px;
            bottom: 40px;
        }

        background: {
            image: url('../images/banner-tablet.jpg');
            position: center top;
        }

        .webp & {
            background-image: url('../images/banner-tablet.webp');
        }

        ul {
            margin: {
                top: 145px;
            }
        }

        .banner-text {
            padding: {
                left: 18px;
            }
        }
    }

    .footer-banner {
        background: {
            image: url('../images/footer-tablet.jpg');
            position: center top;
        }

        .webp & {
            background-image: url('../images/footer-tablet.webp');
        }


        h2 {
            font: {
                size: 68px;
            }

            letter-spacing: 0;

            span {
                letter-spacing: 0;
            }
        }

        ul {
            margin-top: 149px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .main-banner {
        padding: {
            top: 55.5vw;
            bottom: 5.56vw;
        }

        background: {
            image: url('../images/banner-mobile.jpg');
            position: center top;
            size: 100%;
        }

        .webp & {
            background-image: url('../images/banner-mobile.webp');
        }

        .tagline {
            padding: {
                left: 1.43vw;
            }

            font: {
                size: 4.68vw;
            }

            letter-spacing: .156vw;

            strong {
                letter-spacing: .3125vw;
            }
        }

        ul {
            margin: {
                top: 62.71vw;
                bottom: 0;
            }

            padding-left: 3.75vw;

            li {
                padding-left: 7.5vw;
                font-size: 5.625vw;
                line-height: 7.8125vw;

                background: {
                    size: 5.31vw;
                }

                letter-spacing: -.11vw;
            }
        }

        .banner-text {
            padding: 0 4.68vw;
        }
    }

    .footer-banner {
        padding: {
            top: 59.3vw;
            bottom: 77vw;
        }

        background: {
            image: url('../images/footer-mobile.jpg');
            position: center top;
            size: cover;
        }

        .webp & {
            background-image: url('../images/footer-mobile.webp');
        }

        .tagline {
            margin-bottom: 3.9vw;

            strong {
                letter-spacing: .4125vw;
            }
        }

        h2 {
            font: {
                size: 10.625vw;
            }

            text-align: center;
            line-height: 10.31vw;
            letter-spacing: -.42vw;

            span {
                line-height: 10.31vw;
                letter-spacing: -.42vw;
            }
        }

        ul {
            margin-top: 34.71vw;
        }
    }
}