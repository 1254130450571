.helps {
    h2 {
        span {
            display: block;
            color: $brand-primary;
        }
    }

    .heading {
        margin-bottom: 51px;

        p {
            font-size: 20px;
            font-weight: 300;
            color: #000000;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .help-slider {
        margin-bottom: -18px;
        padding: 0 25px;

        .swiper-slide {
            width: 320px;

            &:first-child {
                h3 {
                    max-width: 176px;
                }
            }

            &:not(:last-child) {
                margin-right: 65px;
            }
        }

        .help-picture {
            display: block;
            margin-bottom: 24px;
        }

        h3 {
            margin-bottom: 18px;
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            text-transform: uppercase;
            letter-spacing: -.65px;
        }

        .picture-detail {
            min-height: 218px;

            p {
                font-family: 'Open Sans', sans-serif;
                font-size: 17px;
                color: $gray-base;
                line-height: 28px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .help-quote {
            min-height: 304px;
            padding: 59px 27px 30px;
            background: {
                image: url("../images/help-quote.svg"), url("../images/shadow.png");
                position: left top, center bottom;
                repeat: no-repeat;
            };

            p {
                color: $gray-base;

                span {
                    font-style: italic;
                }
            }
        }
    }
}

@media screen and (max-width: $screen-md-max) {
    .helps {
        .help-slider {
            padding: 0;

            h3 {
                font-size: 23px;
            }

            .swiper-slide {
                width: 300px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            .picture-detail {
                p {
                    font-size: 15px;
                    line-height: 24px;
                }
            }

            .help-quote {
                padding: 45px 20px;
                background-size: 100%;
            }
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .helps {
        padding: {
            top: 53px;
            bottom: 46px;
        };

        h2 {
            margin-bottom: 22px;
        }

        .heading {
            margin-bottom: 47px;
        }

        .help-slider {
            h3 {
                font: {
                    size: 25px;
                };
                letter-spacing: 0.5px;
                padding-right: 21px;
            }

            .swiper-slide {
                width: 100%;

                &:not(:last-child) {
                    margin: 0 0 25px;
                }

                &:nth-child(2) {
                    .picture-detail {
                        p {
                            max-width: 324px;
                        }
                    }
                }

                &:first-child {
                    h3 {
                        max-width: 100%;
                    }

                    .picture-detail {
                        padding: {
                            top: 10px;
                        };
                    }
                }
            }

            .help-content {
                @include flexbox;
                @include flex-flow(wrap);
            }

            .help-picture {
                width: 340px;
            }

            .picture-detail {
                @include calc(width, "100% - 340px");

                margin: {
                    top: -2px;
                };
                padding: {
                    left: 6px;
                };

                p {
                    font: {
                        size: 17px;
                    };
                    line-height: 28px;
                }
            }

            .help-quote {
                min-height: 189px;
                margin-top: -55px;
                padding: 56px 30px 30px;
                background: {
                    image: url("../images/help-quote-tablet.svg"), url("../images/help-quote-bg-tablet.png");
                    position: left top, center bottom;
                    size: auto;
                };
            }
        }

        .swiper-wrapper {
            @include flex-flow(wrap);
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .helps {
        padding: {
            top: 7vw;
            bottom: 10vw;
        };

        h2 {
            margin: {
                bottom: 4.53vw;
            };
        }

        .heading {
            margin-bottom: 6.71vw;

            p {
                font: {
                    size: 5.625vw;
                };
            }
        }

        .swiper-wrapper {
            @include flex-flow(inherit);
        }

        .help-slider {
            text-align: center;

            h3 {
                margin-bottom: 4.375vw;
                padding: 0;
                font: {
                    size: 5.625vw;
                };
                line-height: 6.4vw;
            }

            .help-picture {
                width: 100%;
                margin-bottom: 6.56vw;
                padding: 0 11.375vw;
            }

            .picture-detail {
                width: 100%;
                margin: 0;
                padding: 0;
                min-height: 10px;

                p {
                    padding: 0 2.34vw;
                    font: {
                        size: 4.68vw;
                    };
                    line-height: 6.56vw;
                }
            }

            .swiper-pagination {
                margin: {
                    top: 7.03vw;
                    bottom: 0;
                };
            }

            .swiper-pagination-bullet {
                width: 3.6vw;
                height: 3.6vw;
                border: none;
                margin: 0 2.18vw;
                background: {
                    color: #cccccc;
                };
            }

            .swiper-pagination-bullet-active {
                background-color: $brand-primary;
            }

            .swiper-slide {
                &:first-child {
                    padding: 0;
                }

                &:nth-child(2) {
                    .picture-detail {
                        p {
                            max-width: 100%;
                        }
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
