/*=============================================
=            Body            =
=============================================*/

body {
    font: {
        family: $font-family-sans-serif;
    }

    ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-center {
    .img-responsive {
        margin-right: auto;
        margin-left: auto;
    }
}

*:focus,
a:focus {
    outline: none;
}

/*=====  End of Body  ======*/

/*=============================================
=            Heading            =
=============================================*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: {
        top: 0;
    }

    ;

    font: {
        weight: 600;
    }

    ;
}

h1 {
    font: {
        size: 58px;
        weight: 700;
    }

    ;
    letter-spacing: -2.75px;
    
    line-height: 84px;

    span {
        display: block;

        margin: {
            bottom: -16px;
        }

        ;

        font: {
            size: 88px;
            weight: 300;
        }

        ;
        letter-spacing: -6px;
    }
}

h2 {
    margin-bottom: 18px;

    font: {
        size: 50px;
        weight: 700;
    }

    ;
    line-height: 48px;
    letter-spacing: -1px;
}

p {
    line-height: 20px;
}

/*=====  End of Heading  ======*/

/*=============================================
=            Button            =
=============================================*/

.btn {
    @include transition(all 300ms ease);
}

.btn-get {
    position: relative;
    min-width: 365px;
    border: none;
    border-bottom: 2px solid #3daf00;

    @include border-radius(28px);

    padding: 17px 25px;

    font: {
        size: 22px;
        weight: 800;
    }

    ;
    line-height: 1;
    background: rgba(121, 213, 0, 1);
    background: -moz-linear-gradient(top, rgba(121, 213, 0, 1) 0%, rgba(51, 153, 0, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(121, 213, 0, 1)), color-stop(100%, rgba(51, 153, 0, 1)));
    background: -webkit-linear-gradient(top, rgba(121, 213, 0, 1) 0%, rgba(51, 153, 0, 1) 100%);
    background: -o-linear-gradient(top, rgba(121, 213, 0, 1) 0%, rgba(51, 153, 0, 1) 100%);
    background: -ms-linear-gradient(top, rgba(121, 213, 0, 1) 0%, rgba(51, 153, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(121, 213, 0, 1) 0%, rgba(51, 153, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#79d500', endColorstr='#339900', GradientType=0);

    &:hover,
    &:focus {
        border-bottom: 2px solid #3eb700;
        background: rgba(128, 219, 0, 1);
        background: -moz-linear-gradient(top, rgba(128, 219, 0, 1) 0%, rgba(57, 170, 0, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(128, 219, 0, 1)), color-stop(100%, rgba(57, 170, 0, 1)));
        background: -webkit-linear-gradient(top, rgba(128, 219, 0, 1) 0%, rgba(57, 170, 0, 1) 100%);
        background: -o-linear-gradient(top, rgba(128, 219, 0, 1) 0%, rgba(57, 170, 0, 1) 100%);
        background: -ms-linear-gradient(top, rgba(128, 219, 0, 1) 0%, rgba(57, 170, 0, 1) 100%);
        background: linear-gradient(to bottom, rgba(128, 219, 0, 1) 0%, rgba(57, 170, 0, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80db00', endColorstr='#39aa00', GradientType=0);
    }

}

.btn-getbox {
    position: relative;

    .btn-shadow {
        position: absolute;
        display: block;
        content: '';
        width: 378px;
        height: 86px;
        top: -5px;
        left: -5px;

        background: {
            image: url("../images/btn-desktop-shadow.png");
            position: left top;
            repeat: no-repeat;
        }

        .webp & {
            background-image: url('../images/btn-desktop-shadow.webp');
        }
    }

    &.text-center {
        .btn-shadow {
            left: 50%;
            @include translate(-50%, 0);
            margin-left: -4px;
        }
    }
}

/*=====  End of Button  ======*/

/*=============================================
=            Section            =
=============================================*/
.section {
    padding: {
        top: 50px;
        bottom: 50px;
    }

    ;

    p {
        margin-bottom: 14px;
    }
}

.section-gray {
    background-color: #f7f7f7;
}

/*=====  End of Section  ======*/

@media screen and (max-width: $screen-md-max) {

    /*=============================================
    =            Heading            =
    =============================================*/
    h1 {
        font: {
            size: 59px;
        }

        ;

        span {
            font: {
                size: 80px;
            }

            ;
        }
    }

    /*=====  End of Heading  ======*/
}

@media screen and (max-width: $screen-sm-max) {

    /*=============================================
    =            Heading            =
    =============================================*/
    h1 {
        font: {
            size: 68px;
        }

        ;

        span {
            font: {
                size: 90px;
            }

            ;
        }
    }

    /*=====  End of Heading  ======*/

    /*=============================================
    =            Button            =
    =============================================*/

    .btn-get {
        min-width: 348px;

    }

    .btn-getbox {
        .btn-shadow {
            width: 358px;

            background: {
                image: url('../images/button=shadow-tablet.png');

            }

            .webp & {
                background-image: url('../images/button=shadow-tablet.webp');
            }
        }
    }

    .answer-button {
        .btn-shadow {
            left: 50%;
            margin-left: -5px;
            @include translate(-50%, 0);
        }
    }

    /*=====  End of Button  ======*/

    /*=============================================
    =            Swiper Slider            =
    =============================================*/
    .swiper-container-horizontal {
        .swiper-pagination-bullets {
            position: relative;
            bottom: 0;
            margin: 27px 0 20px;

            .swiper-pagination-bullet {
                width: 13px;
                height: 13px;
                border: 2px solid $body-bg;
                margin: 0 9px;
                opacity: 1;
                background-color: transparent;
            }

            .swiper-pagination-bullet-active {
                background-color: $body-bg;
            }
        }
    }

    /*=====  End of Swiper Slider  ======*/
}

@media screen and (max-width: $screen-xs-max) {

    /*=============================================
    =            Heading            =
    =============================================*/
    h1 {
        font-size: 9.625vw;
        letter-spacing: -.74vw;
        line-height: 52px;

        span {
            margin-bottom: -1.875vw;

            font-size: 13.06vw;

            letter-spacing: -1.04vw;
        }
    }

    h2 {
        margin-bottom: 4.68vw;

        font: {
            size: 7.81vw;
        }

        ;
        line-height: 7.5vw;
        letter-spacing: -.18vw;
    }

    /*=====  End of Heading  ======*/

    /*=============================================
    =            Button            =
    =============================================*/

    .btn-get {
        min-width: 90.625vw;

        @include border-radius(7.18vw);

        padding: 4.53vw;

        font: {
            size: 5.625vw;
        }

    }

    .btn-getbox {
        .btn-shadow {
            width: 90.625vw;
            height: 17.81vw;
            top: 0;
            left: 50%;
            margin: 0;

            @include translate(-50%, 0);

            background: {
                image: url('../images/btn-shaddow-mobile.png');
            }

            .webp & {
                background-image: url('../images/btn-shaddow-mobile.webp');
            }
        }
    }

    /*=====  End of Button  ======*/

    /*=============================================
    =            Section            =
    =============================================*/
    .section {
        padding: {
            top: 7.5vw;
            bottom: 7.03vw;
        }

        ;

        p {
            font: {
                size: 4.68vw;
            }

            ;
            line-height: 6.7vw;
        }
    }

    /*=====  End of Section  ======*/
    /*=============================================
    =            Swiper Slider            =
    =============================================*/
    .swiper-container-horizontal {
        .swiper-pagination-bullets {
            margin: {
                top: 7.03vw;
                bottom: 0;
            }

            ;

            .swiper-pagination-bullet {
                width: 3.6vw;
                height: 3.6vw;
                border: none;
                margin: 0 2.18vw;

                background: {
                    color: #cccccc;
                }

                ;
            }

            .swiper-pagination-bullet-active {
                background-color: $brand-primary;
            }
        }
    }

    /*=====  End of Swiper Slider  ======*/
}