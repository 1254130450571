.navbar-default {
    border: none;

    @include border-radius(0);

    margin: 0;
    padding: 22px 0 14px;

    background: {
        color: $body-bg;
    }

    @include box-shadow(0px 2px 7px 0px rgba(0, 0, 0, 0.23));

    .navbar-header {
        margin: {
            top: 4px;
            left: 24px;
        }
    }

    .navbar-nav {
        >li {
            &:not(:last-child) {
                margin: {
                    top: 6px;
                    right: 37px;
                }
            }

            >a {
                &:not(.btn) {
                    padding: 0;

                    font: {
                        size: 16px;
                    }

                    color: $gray-base;

                    background: {
                        color: transparent;
                    }

                    @include transition(all 300ms ease);

                    &:hover,
                    &:focus,
                    &:active {
                        text: {
                            decoration: underline;
                        }

                        color: $brand-primary;
                    }
                }

                &.btn {
                    border: none;

                    @include border-radius(18px);

                    padding: 9px 18px;

                    font: {
                        weight: 800;
                    }

                    text: {
                        transform: uppercase;
                    }

                    background: {
                        color: #54b500;
                    }

                    color: $body-bg;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $body-bg;

                        background: {
                            color: #59bf00;
                        }
                    }
                }
            }

            &.active {
                >a {
                    background: {
                        color: transparent;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        background: {
                            color: transparent;
                        }
                    }
                }
            }
        }

        &.pull-right {
            padding: {
                right: 10px;
            }
        }
    }

    .navbar-brand {
        height: auto;
        padding: 0 0 0 15px;
    }
}

@media screen and (max-width: $screen-sm-max) {
    .navbar-default {
        .navbar-header {
            @include flexbox;
            @include align-items(center);
            width: 100%;
            margin: 0 auto 19px;
        }

        >.container {
            .navbar-brand {
                margin: {
                    left: auto;
                    right: auto;
                }

            }
        }

        .navbar-collapse {
            padding: {
                right: 4px;
            }
        }

        .navbar-nav {
            width: 100%;

            &.pull-right {
                padding: {
                    right: 0;
                }
            }

            >li {
                &:not(:last-child) {
                    margin: {
                        right: 31px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $screen-xs-max) {
    .navbar-default {
        padding: {
            top: 3.75vw;
            bottom: 4.06vw;
        }

        >.container {
            .navbar-brand {
                max-width: 37.96vw;
                margin: 0.3125vw 18.59vw 0;
                padding: 0;
            }
        }

        .navbar-header {
            @include flex-flow(row-reverse);

            margin: {
                bottom: 0;
            }
        }


        .navbar-toggle {
            max-width: 7.65vw;
            border: none;
            @include border-radius(0);

            margin: {
                top: 0;
                right: 0;
                bottom: 0;
            }

            padding: 0;

            &:hover,
            &:focus,
            &:active {
                background: {
                    color: transparent;
                }
            }
        }

        .navbar-collapse {
            width: 100%;

            border: none;
            padding: 0 4.68vw;

            background: {
                color: $body-bg;
            }
        }

        .navbar-nav {
            padding: {
                top: 6vw;
            }

            >li {
                &:not(:last-child) {
                    margin: 0 0 6.4vw;
                    text-align: center;

                    >a {
                        font: {
                            size: 4.68vw;
                        }

                        line-height: 1;

                        &:hover,
                        &:focus,
                        &:active {

                            text-decoration: none;
                        }
                    }
                }

                &:nth-last-child(2) {
                    margin: {
                        bottom: 2vw;
                    }
                }
            }
        }
    }

}